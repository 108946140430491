import 'web-animations-js';
import Misc from './misc';

(function($) {
	
	$('.item--menu-element .uppercase').on('click', function(event) {
		if ( Misc.getViewport().width > Misc.getMediaQueries()['mobile-layout']) {
			return false;
		}
		event.preventDefault();
		$(this).parent().find('ul').slideToggle(300);
		$(this).toggleClass('active');
	});

	$(window).on('resize', function() {
		if ( Misc.getViewport().width > Misc.getMediaQueries()['mobile-layout']) {
			$('.item--menu-element ul').removeAttr('style');
		}
	})

	// console.log($);
})( jQuery );