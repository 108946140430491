(function($) {
	$('.search-header-wrapper input').on('input', function() {
		if ($(this).val().length > 3) {
			$('.search-header-wrapper .helper').addClass('fade-in');
		} else {
			$('.search-header-wrapper .helper').removeClass('fade-in');
		}
	})
	$('.header-wrapper a.search').on('click', (event) => {
		event.preventDefault();
		$('.search-header-wrapper').slideToggle({
			duration: 300,
			complete: () => {
				$('#header-search input').focus();
				$('.header-wrapper a.search').toggleClass('active');	
			}
		});
	});
})( jQuery );