(function($) {
	
	$('.content-faqs--item .title').on('click', function(event) {
		event.preventDefault();
		
		$('.content-faqs--item .entry-content').slideUp(300);
		if ($(this).hasClass('active')) {
			$(this).removeClass('active');
		} else {
			$('.content-faqs--item .title').removeClass('active');
			$(this).addClass('active');
			$(this).parent().find('.entry-content').slideToggle(300);
		}

		return false;

	});

	$('.content-faqs--nav a').on('click', function(event) {
		event.preventDefault();
		const searchTermTrigger = $(this);
		const searchTermId = parseInt($(this).data('term-id'));

		if(searchTermId === 0 && searchTermTrigger.hasClass('active')) {
			return false;
		}

		if(searchTermId === 0 && !searchTermTrigger.hasClass('active')) {
			$('.content-faqs--item').slideDown(300);
			$('.content-faqs--nav a').removeClass('active');
			searchTermTrigger.addClass('active');

		} else {
			$('.content-faqs--item').each(function(item, index) {
				const postTerms = $(this).data('terms-id');
				let postHasTerm = false;
				postTerms.forEach(itemTerm => {
					if (searchTermId === itemTerm)
						postHasTerm = true;
				});

				$('.content-faqs--nav a').removeClass('active');

				if (!postHasTerm) {
					$(this).slideUp(300);
				} else {
					$(this).slideDown(300);
					searchTermTrigger.addClass('active');
				}

			});

		}

	});

})( jQuery );