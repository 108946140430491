import Misc from './misc';

(function($) {

  const topBannerWrapper = document.querySelector('.content-top-banner');
  const buttonClose = document.querySelector('.content-top-banner .op-close');
  const isTopBannerDisplayed = Misc.getCookie('show_top_banner');

  if (!topBannerWrapper || !buttonClose) {
    return false;
  }

  $(buttonClose).on('click', function(){
    $(topBannerWrapper).slideUp(300);
    let cookieNameID = $(buttonClose).data('cookie-name-id');
    Misc.setCookie(`show_top_banner_${cookieNameID}`, '0', 31);
  });

})( jQuery );