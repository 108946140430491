import smoothscroll from 'smoothscroll-polyfill';
import Swiper from 'swiper';
import 'promise-polyfill/dist/polyfill.min';
import 'formdata-polyfill';
import Misc from './misc';
import './imageOnLoad';
import './header';
import './top-banner';
import './footer';
import './search';
import './faqs';
require('formdata-polyfill');

// import AOS from 'aos';
// import 'aos/dist/aos.css';
// AOS.init({
//   once: true,
//   mirror: false
// });

var mySwiper = new Swiper('.swiper-container', { 
    direction: 'horizontal',
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 6000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
});

var fullPageSwiper = new Swiper('.swiper-container--overview', { 
    direction: 'horizontal',
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 6000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.swiper-pagination',
    },
});

if (document.body.classList.contains('page-villages--summary')) {
  (function($) {
    let sliders = [];
    $('.swiper-container').each(function(index, element){
        $(this).addClass('swiper-container--summary'+index);
        let slider = new Swiper('.swiper-container--summary'+index, {
          direction: 'horizontal',
          loop: true,
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          autoplay: {
            delay: 6000,
          },
          effect: 'fade',
          fadeEffect: {
            crossFade: true
          },
          pagination: {
            el: '.swiper-pagination',
          },
        });
        sliders.push(slider);
    });
  })( jQuery );
}

// NodeList.prototype.forEach polyfill
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}