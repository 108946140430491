import 'web-animations-js';
import Misc from './misc';

class headerMenu { 
	constructor() {

		this.mobileMenuLayout = Misc.getMediaQueries()['mobilemenu-layout'];
		this.triggerMenu = document.querySelector('.header-wrapper .header-menu-trigger a');
		this.headerWrapper = document.querySelector('.header-wrapper')
		this.menuWrapper = document.querySelector('.header-wrapper .item--nav');
		this.toggleMenu = this.toggleMenu.bind(this);
		this.handleOnResize = this.handleOnResize.bind(this);
		this.handleOnScroll = this.handleOnScroll.bind(this);

		// Init if mobilemenu
		if (Misc.getViewport().width < this.mobileMenuLayout) {
			window.bodyLayout = 'mobilemenu-layout';
			this.triggerMenu.addEventListener('click', this.toggleMenu);
		}

		// Handle on Resize
		window.addEventListener('resize', this.handleOnResize);
		window.addEventListener('scroll', this.handleOnScroll);
		 
	}

	toggleMenu(event) {
		event.preventDefault();
		const menuNavHeight = parseInt(this.menuWrapper.querySelector('nav').getBoundingClientRect().height);
		if (!this.triggerMenu.classList.contains('active')) {
			this.openMenu();
		} else {
			this.closeMenu();
		}
	}

	openMenu() {
		const menuNavHeight = parseInt(this.menuWrapper.querySelector('nav').getBoundingClientRect().height);
		const animation = this.menuWrapper.animate({
			height: [0, `${menuNavHeight}px`],
			opacity: [0, 1],
		}, {
			duration: 200, 
			fill: 'forwards',
		});
		animation.onfinish = () => {
			this.triggerMenu.classList.add('active');
			this.menuWrapper.style.setProperty('height', `${menuNavHeight}px`);
			this.menuWrapper.style.setProperty('opacity', 1);
			animation.cancel();
		}
	}

	closeMenu() {
		const menuNavHeight = parseInt(this.menuWrapper.querySelector('nav').getBoundingClientRect().height);
		const animation = this.menuWrapper.animate({
			height: [0, `${menuNavHeight}px`],
			opacity: [0, 1],
		}, {
			duration: 200, 
			fill: 'forwards',
			direction: 'reverse'
		});
		animation.onfinish = () => {
			this.triggerMenu.classList.remove('active');
			this.menuWrapper.style.removeProperty('height');
			this.menuWrapper.style.removeProperty('opacity');
			animation.cancel();
		}    
	}

	handleOnResize() {
		if (Misc.getViewport().width > this.mobileMenuLayout && window.bodyLayout === 'nondesktop-layout') {
			window.bodyLayout = null;
			this.triggerMenu.removeEventListener('click', this.toggleMenu);
			this.triggerMenu.classList.remove('active');
			this.menuWrapper.removeAttribute('style')
		}

		if (Misc.getViewport().width < this.mobileMenuLayout && window.bodyLayout !== 'nondesktop-layout') {
			window.bodyLayout = 'nondesktop-layout';
			this.triggerMenu.addEventListener('click', this.toggleMenu);
		}
	}

	handleOnScroll(event) {
		const scrollTop = document.documentElement.scrollTop;

		if (!this.headerWrapper.classList.contains('header-wrapper--absolute')) {
			return false;
		}
		
		if (Misc.getViewport().width < this.mobileMenuLayout) {
			return this.headerWrapper.classList.remove('header-wrapper--fixed')
		}

		if (scrollTop > 0) {
			this.headerWrapper.classList.add('header-wrapper--fixed')
		} else {
			this.headerWrapper.classList.remove('header-wrapper--fixed')
		}
	}

}

window.onload = function() {
	new headerMenu();
};