class imageOnLoad {

	static add(options, callback) {
		const imageParent = document.querySelectorAll(options.wrapper)

		if (!imageParent) {
			return false;
		}
		
		imageParent.forEach(item => {
			const imageEl = item.querySelector(options.selector)
			const imageToLoad = new Image();
			imageToLoad.setAttribute('src', imageEl.getAttribute('data-image-desktop') )
			imageToLoad.onload = function() { 
				imageEl.classList.remove('unloaded');
				if (options.setHasBackgroundImage) {
					imageEl.style.backgroundImage = 'url('+imageToLoad.src+')';
				} else {
					imageEl.setAttribute('src', imageToLoad.src);
				}
				typeof callback === 'function' && callback();
			}

		});
	}
};

document.addEventListener('DOMContentLoaded', () => {
	imageOnLoad.add({
		wrapper: '.hero-wrapper',
		selector: '.hero-wrapper--bg',
		setHasBackgroundImage: true
	});

	imageOnLoad.add({
		wrapper: '.content-discover--item-inner',
		selector: 'img',
		setHasBackgroundImage: false
	});
});
